<template>

     <template v-if="currentSchoolyear && adminSchoolyear">

        <el-card shadow="hover">
            <el-row align="middle">
                <el-col :span="24">
                    <div class="text-left">
                        <h3>{{ $t('coursesRepartitionPerClass') }}</h3>
                    </div>
                </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-table :data="pagedTableData" :empty-text="$t('noData')" style="width: 100%" border stripe v-loading="isLoading">

                <el-table-column type="index" :index="indexMethod" width="50" fixed> </el-table-column>
                <el-table-column sortable :label="$t('name')" prop="name" width="175" fixed> </el-table-column>
                <el-table-column sortable :label="$t('establishment')" prop="establishment" width="140" fixed>
                    <template #default="scope">
                        {{ getEstablishmentLocation(scope.row.establishment) }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('level')" prop="level" width="140">
                    <template #default="scope">
                        {{ getLevelName(scope.row.level) }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('incumbent')" prop="incumbent" width="160">
                    <template #default="scope">
                        {{ getTeacherName(scope.row.incumbent) }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('courses')" prop="coursesNumber" width="120">
                    <template #default="scope">
                        {{ scope.row.coursesNumber || '0' }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('particularities')" prop="features" width="250">
                    <template #default="scope">
                        <el-tag v-for="(item, id) in getFeatures(scope.row.features)" :key="'tag_' + item" :type="id % 2 === 0 ? 'success' : ''" class="mb-3">
                            {{ item }}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column align="right" width="225" fixed="right">

                    <template #header>
                        <el-input v-model="search" size="small" :placeholder="$t('search')" />
                    </template>

                    <template #default="scope">
                        <el-button size="small" type="primary" @click="repartition(scope.$index, scope.row)">
                            {{ $t('repartition') }}
                        </el-button>
                        <el-button size="small" type="success" @click="setIncumbent(scope.$index, scope.row)">
                            {{ $t('incumbent') }}
                        </el-button>
                    </template>

                </el-table-column>

            </el-table>

            <div style="text-align: center; margin-top: 20px">

                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="setPage"
                    :hide-on-single-page="true"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>

            </div>
        </el-card>

        <el-dialog
            fullscreen
            v-model="repartitionDialog"
            @close="cancelRepartition()"
            :title="$t('coursesRepartitionForClass', [this.currentClass ? (this.currentClass.name + ' - ' + getEstablishmentLocation(this.currentClass.establishment)) : ''])">

            <el-row align="middle">
                <el-col :span="12">
                    <div class="text-left">
                        <h3>{{ $t('coursesList') }} <span>{{ (rows && rows.length) ?  ('  (' + rows.length + ')') : '' }}</span></h3>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-right">
                        <el-button @click="addRow()" type="info">{{ $t('add') }}</el-button>
                    </div>
                </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-row :gutter="20" v-for="(row, index) in rows" :key="'row_' + index" >
                <el-col :span="5">
                    <label class="block-label">{{ $t('course') }}</label>
                    <el-select
                        filterable
                        v-model="row.course"
                        :no-data-text="$t('noData')"
                        :placeholder="$t('selectCourse')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 100%; margin-top: 5px">
                        <el-option
                            v-for="course in courses"
                            :key="course.id"
                            :value="course.id"
                            :label="course.name"
                            :disabled="selectedCourses.findIndex(s => s.id == course.id) > -1">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="5">
                    <label class="block-label">{{ $t('teacher') }}</label>
                    <el-select
                        filterable
                        v-model="row.teacher"
                        :no-data-text="$t('noData')"
                        :placeholder="$t('selectTeacher')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 100%; margin-top: 5px">
                        <el-option
                            v-for="teacher in teachers"
                            :key="teacher.id"
                            :value="teacher.id"
                            :label="teacher.name">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="5">
                    <label class="block-label">{{ $t('section') }}</label>
                    <el-select
                        clearable
                        filterable
                        v-model="row.section"
                        :no-data-text="$t('noData')"
                        :placeholder="$t('selectSection')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 100%; margin-top: 5px">
                        <el-option
                            v-for="section in sections"
                            :key="section.id"
                            :value="section.id"
                            :label="section.name">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <label class="block-label" style="width: 50%">{{ $t('coef') }}</label>
                    <el-input-number
                        v-model="row.coef"
                        style="width: 100%; margin-top: 5px"
                        controls-position="right"
                        :placeholder="$t('coef')"
                        :max="100"
                        :min="1"/>
                </el-col>
                <el-col :span="3">
                    <label class="block-label" style="width: 50%">{{ $t('position') }}</label>
                    <el-input-number
                        v-model="row.idx"
                        style="width: 100%; margin-top: 5px"
                        controls-position="right"
                        :placeholder="$t('position')"
                        :max="100"
                        :min="0"/>
                </el-col>
                <el-col :span="3">
                    <label class="block-label">{{ $t('actions') }}</label>
                    <el-button @click.prevent="removeRow(row)" style="width: 100%; margin-top: 5px">{{ $t('delete') }}</el-button>
                </el-col>
                <el-divider></el-divider>
            </el-row>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelRepartition()">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitRepartition()">{{ $t('confirm') }}</el-button>
                </span>
            </template>

        </el-dialog>

        <el-dialog
            width="40%"
            v-model="incumbentDialog"
            @close="cancelIncumbent()"
            :title="$t('incumbentForClass', [this.currentClass ? (this.currentClass.name + ' - ' + getEstablishmentLocation(this.currentClass.establishment)) : ''])">

            <div class="text-center">
                <el-select
                    filterable
                    style="width: 70%"
                    v-model="currentIncumbent"
                    :placeholder="$t('select')"
                    :no-data-text="$t('noData')"
                    :no-match-text="$t('noMatchingData')">
                    <el-option
                        v-for="item in teachers"
                        :key="item.id"
                        :value="item.id"
                        :label="item.name">
                    </el-option>
                </el-select>
            </div>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelIncumbent()">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitIncumbent()">{{ $t('confirm') }}</el-button>
                </span>
            </template>

        </el-dialog>

     </template>

     <el-alert :title="$t('noCurrentSchoolyearWarning')" type="warning" v-else/>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import fr from 'element-plus/lib/locale/lang/fr'

import { Features } from '@/utils/enums'

import accessManager from '@/mixins/access-manager'

export default {
    name: 'CoursesRepartition',
    mixins: [accessManager],
    data() {
      return {
        rows: [],
        levels: [],
        courses: [],
        sections: [],
        teachers: [],
        features: [],
        departments: [],
        establishments: [],
        primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
        repartitionDialog: false,
        incumbentDialog: false,
        currentIncumbent: null,
        currentClass: null,
        isLoading: false,
        tableData: [],
        pageSize: 6,
        locale: fr,
        search: '',
        total: 0,
        page: 1
      }
    },
    created() {

      this.fetchClasses()

      var self = this

      this.$_.forIn(Features, function(value, key) {

          self.features.push(value)

      })

    },
    computed:{
      ...mapState({
        userData: state => state.session.userData
      }),
      ...mapGetters({
        getSettingValue: 'settings/getSettingValue'
      }),
      pagedTableData() {

        this.total = this.searching.length

        return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

      },
      searching() {

        if (!this.search) {
            this.total = this.tableData.length
            return this.tableData
        }

        this.page = 1

        return this.tableData.filter(data => data.name.toLowerCase().includes(this.search.toLowerCase()))

      },
      selectedCourses() {

        return this.courses.filter(course => this.rows.findIndex(r => r.course == course.id) > -1)

      }
    },
    methods: {
      ...mapActions({
         fetchEstablishmentsDB: 'establishments/fetchEstablishmentsDB',
         fetchCourseSectionsDB: 'coursesections/fetchCourseSectionsDB',
         fetchDepartmentsDB: 'departments/fetchDepartmentsDB',
         fetchRepartitionDB: 'repartition/fetchRepartitionDB',
         fetchTeachersDB: 'teachers/fetchTeachersDB',
         fetchCoursesDB: 'courses/fetchCoursesDB',
         fetchClassesDB: 'classes/fetchClassesDB',
         fetchLevelsDB: 'levels/fetchLevelsDB',
      }),
      setPage (val) {

         this.page = val

      },
      indexMethod(index) {

         return index + ((this.page - 1) * this.pageSize) + 1

      },
      getLevelName(id){

          let level = this.levels.find(l => l.id == id)

          return level ? level.name : ''

      },
      getDepartmentName(id){

          let department = this.departments.find(d => d.id == id)

          return department ? department.name : ''

      },
      getTeacherName(id){

          let teacher = this.teachers.find(t => t.id == id)

          return teacher ? (teacher.name) : ''

      },
      getEstablishmentLocation(id){

          let establishment = this.establishments.find(e => e.id == id)

          return establishment ? establishment.location : ''

      },
      getFeatures(str){

          let tabs = []
          let parts = str.split(',')

          parts.forEach(part => {
            let feature = this.features.find(f => f.id == part)

            if (feature) tabs.push(feature.label)
          })

          return tabs

      },
      async fetchClasses(){

        if (!this.adminSchoolyear) return

        this.isLoading = true

        let resp6 = await this.fetchCourseSectionsDB({
            action: 'getCourseSections',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp6.success && resp6.data) this.sections = resp6.data

        let resp5 = await this.fetchCoursesDB({
            action: 'getCourses',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp5.success && resp5.data) this.courses = resp5.data

        let resp4 = await this.fetchEstablishmentsDB({
            action: 'getEstablishments',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp4.success && resp4.data) this.establishments = resp4.data

        let resp0 = await this.fetchTeachersDB({
            action: 'getTeachers',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp0.success && resp0.data) this.teachers = resp0.data

        let resp1 = await this.fetchDepartmentsDB({
            action: 'getDepartments',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp1.success && resp1.data) this.departments = resp1.data

        let resp2 = await this.fetchLevelsDB({
            action: 'getLevels',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp2.success && resp2.data) this.levels = resp2.data

        let resp3 = await this.fetchClassesDB({
            action: 'getClasses',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp3.success){

            if (resp3.data) this.tableData = resp3.data

        }else{

            this.$message.error(this.$t('anErrorOccured'))

        }

        this.fetchCoursesNumber()

        this.isLoading = false

      },
      async fetchCoursesNumber(){

          for (let i = 0; i < this.tableData.length; i++) {

              let resp = await this.fetchRepartitionDB({
                  action: 'getClassCourses',
                  params: {
                    class: this.tableData[i].id,
                    schoolyear: this.adminSchoolyear
                  }
              })

              if (resp.success && resp.data) this.tableData[i].coursesNumber = resp.data.length
              else this.tableData[i].coursesNumber = 0

          }

      },
      removeRow(row){

        var index = this.rows.indexOf(row)

        if (index !== -1) this.rows.splice(index, 1)

      },
      addRow(){

        this.rows.push({
          course: '',
          teacher: '',
          section: '',
          coef: 0,
          idx: 0
        })

      },
      async repartition(index, item){

        if (this.lockedData){
            this.$message.warning(this.$t('lockedData'))

            return
        }

        this.currentClass = item

        let resp = await this.fetchRepartitionDB({
            action: 'getClassCourses',
            params: {
                class: item.id,
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp.success){

            if (resp.data) {

                resp.data.forEach(v => {

                  this.rows.push({
                    course: v.course,
                    teacher: v.teacher,
                    section: v.section == '0' ? '' : v.section,
                    coef: parseInt(v.coef),
                    idx: parseInt(v.idx)
                  })

                })
            }

            this.repartitionDialog = true

        }else{

            this.$message.error(this.$t('anErrorOccured'))

        }

      },
      setIncumbent(index, item){

        if (this.lockedData){
            this.$message.warning(this.$t('lockedData'))

            return
        }

        this.currentClass = item
        this.currentIncumbent = item.incumbent != '0' ? item.incumbent : ''

        this.incumbentDialog = true

      },
      async submitRepartition(){

        if (this.rows && this.currentClass){

            let filteredRows = this.rows.filter(r => (r.course && r.teacher && r.coef))

            filteredRows.forEach(row => { if (!row.section) row.section = 0 })

            if (filteredRows && filteredRows.length){

                let task = await this.fetchRepartitionDB({
                    action: 'updateRepartition',
                    params: {
                        schoolyear: this.adminSchoolyear,
                        class: this.currentClass.id,
                        data: filteredRows
                    }
                })

                if (task.success) this.$message.success(this.$t('success'))
                else this.$message.error(this.$t('anErrorOccured'))

            } else {

                await this.fetchRepartitionDB({
                    action: 'updateRepartition',
                    params: {
                        schoolyear: this.adminSchoolyear,
                        class: this.currentClass.id,
                        data: 'clear'
                    }
                })

            }

            this.fetchCoursesNumber()

        }

        this.cancelRepartition()

      },
      cancelRepartition(){

        this.repartitionDialog = false
        this.currentClass = null
        this.rows = []

      },
      async submitIncumbent(){

        if (this.currentIncumbent && this.currentClass) {

            let task = await this.fetchClassesDB({
                action: 'editClass',
                params: {
                    id: this.currentClass.id,
                    data: {
                        incumbent: this.currentIncumbent
                    }
                }
            })

            if (task.success) {

                this.cancelIncumbent()
                this.fetchClasses()

                this.$message.success(this.$t('success'))

            } else this.$message.error(this.$t('anErrorOccured'))

        }

      },
      cancelIncumbent(){

        this.incumbentDialog = false
        this.currentIncumbent = null
        this.currentClass = null

      }
    },
}
</script>

<style scoped>
    .inline-label{
        width: 30%;
        font-weight: bold;
        display: inline-block;
    }

    .block-label{
        font-weight: bold;
        font-size: .9em;
    }
</style>